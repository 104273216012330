// Libs
import React, { useState, useEffect, useContext } from 'react';
import { graphql, StaticQuery } from 'gatsby';
import flatten from 'lodash.flatten';
import styled, { css } from 'styled-components';

// Components
import Layout from 'components/layout';
import { device } from 'components/device';
import SEO from 'components/seo';
import TextSection from 'components/units/unitPages/details/TextSection';
import PromptServiceContent from 'components/promptService/promptServiceContent';
import ListPosts from 'components/blog/listPosts';
import Qa from 'components/Qa';
import { FeaturedMarketing } from 'components/CmsComponents/FeaturedMarketing';
import Components from 'components/CmsComponents/';
import { HeaderContext } from 'context/header';

// Styles
const GridContainer = styled.div`
  display: ${props => (props.isDesktop ? 'grid' : 'flex')};
  grid-template-columns: ${props =>
    props.isDesktop && 'auto repeat(12, 70px) auto'};
  column-gap: ${props => props.isDesktop && '30px'};
  flex-direction: ${props => !props.isDesktop && 'column'};
  padding: ${props => !props.isDesktop && '20px'};

  @media ${device.laptop} {
    flex-direction: column;
    padding-bottom: 0;
    padding-top: 0;
    margin-top: 0;
  }
`;

const Part = styled.div`
  display: ${props => props.isDisplay};
  grid-column: ${props => props.gridColumn};
  border-bottom: ${props => props.borderBottom && '1px solid #C6C8CC'};
  padding-top: ${props => props.paddingTop && '70px'};
  border-bottom: ${props =>
    props.isNotBorder ? 'unset' : '1px solid #c6c8cc'};
  padding-bottom: ${props => props.paddingBottomQa && '3rem'};

  section :first-child {
    padding: ${props =>
      props.notPadding
        ? '70px 0 0 !important'
        : props.notPaddingTop && '0 0 70px !important'};
    p {
      padding-left: ${props => props.notLeft && '0'};
    }

    @media ${device.laptop} {
      width: ${props => props.notPaddingTop && '100%'};
      padding: ${props =>
        props.notPadding
          ? '30px 0 0 !important'
          : props.notPaddingTop && '30px 0 !important'};
      margin: 0;
      border-bottom: ${props => props.notPaddingTop && 'unset'};
    }

    @media ${device.tablet} {
      padding: ${props => props.notPaddingTop && '0 0 30px !important'};
    }
  }

  section :last-child {
    padding: ${props => props.shortPadding && '20px 0 70px'};
    @media ${device.laptop} {
      padding: ${props => props.shortPadding && '0 0 30px'};
    }
  }

  section {
    div {
      div {
        h2 {
          width: ${props => props.largeTitle && '28rem'};

          @media ${device.laptop} {
            width: 100%;
          }
        }
      }
    }
  }

  :first-child {
    display: flex;
    justify-content: space-between;
    align-items: ${props => (props.notFirstChild ? 'unset' : 'center')};
    padding: ${props => props.isPaddingTop && '70px 0 0'};

    @media ${device.laptop} {
      margin-bottom: 0;
      padding: ${props => props.isPaddingTop && '0 0 1.875rem'};
    }

    @media ${device.tablet} {
      margin-bottom: ${props => (props.isCDIBelaVista ? '0' : '1.875rem')};
      padding: ${props => props.isPaddingTop && '1.875rem 0 1.875rem'};
    }

    @media ${device.mobile} {
      margin-bottom: 0;
      padding: ${props => props.isPaddingTop && '0 0 1.875rem'};
    }
  }

  :last-child {
    margin-bottom: 0;
    margin-bottom: ${props => props.isLastSection && '4.375rem'};

    @media ${device.laptop} {
      margin-bottom: ${props => props.isLastSection && '1.25rem'};
    }
  }

  @media ${device.laptop} {
    flex-direction: column;
    margin-top: ${props =>
      props.isMarginTopCustomizedMob ? '1.25rem !important' : '0'};
    margin: ${props => props.isMarginFullWidth && '0 -1.25rem'};
    margin-bottom: ${props => props.isMarginBottomCustomizedMob && '1.25rem '};
    padding-top: ${props => props.paddingTop && '1.875rem'};
    padding-bottom: ${props => props.notPaddingTop && '1.875rem'};
    border-bottom: ${props =>
      props.borderBottom || props.test
        ? 'unset'
        : props.notPaddingTop && '1px solid #C6C8CC'};
    border-top: ${props => props.paddingTop && '1px solid #C6C8CC'};
    section {
      div {
        display: ${props => props.paddingTop && 'flex'};
      }
    }
  }

  @media ${device.tablet} {
    padding-top: ${props => props.paddingTop && '0'};
    border-top: ${props => props.paddingTop && 'unset'};
  }

  ${props =>
    props.padding &&
    css`
      padding: 4.375rem 0;

      @media ${device.laptop} {
        padding: 1.875rem 0;
      }
    `}

  ${props =>
    props.borderTop &&
    css`
      border-top: 1px solid #c6c8cc;
    `}
`;

const getMarkdown = (page, fragmentId, isMarkdown = false, assets = false) => {
  const { fragments } = page[0];
  const findFragment = fragments.find(item => item.id === fragmentId);

  if (isMarkdown) {
    const { markdown } = flatten(findFragment.localizations)[0];
    return markdown;
  } else if (assets) {
    return findFragment.assetpicker;
  } else {
    return flatten(findFragment.localizations);
  }
};

const RenderPage = (page, isDesktop, pageContext) => {
  const featuredMarketingContent = page[0]?.featuredMarketing;
  const {
    setMainMarginTopLaptop,
    setMainMarginTopMobile,
    showAlertPostFeriado,
    isVisibleNovoSite,
  } = useContext(HeaderContext);

  const components = page[0]?.components ?? [];
  const doctorAndSpecialtyContent = components[0];
  const highlightContent = components[1];

  useEffect(() => {
    if (showAlertPostFeriado || isVisibleNovoSite) {
      setMainMarginTopLaptop('9.075rem');
      setMainMarginTopMobile('7.875rem');
    } else {
      setMainMarginTopLaptop('7.063rem');
      setMainMarginTopMobile('5.875rem');
    }
  }, [showAlertPostFeriado, isVisibleNovoSite]);

  return (
    <>
      {featuredMarketingContent && (
        <FeaturedMarketing content={featuredMarketingContent} />
      )}
      <GridContainer isDesktop={isDesktop}>
        <Part
          gridColumn="2 / -2"
          width="100%"
          isDisplay="flex"
          notPaddingTop
          borderBottom
          isPaddingTop
          notFirstChild
        >
          <TextSection // Sobre o nosso núcleo
            markdown={getMarkdown(page, 'ckpe6gg806jlz0b74dew23dep', true)}
            isAbout
            isShowing
            isCDICardio
            isPatologica
            colorText
            isBackground
            isOthersUnits
            isBorder={false}
            containerWidth="50%"
            noMarginRightPage
          />
          <TextSection // Endereço e Horarios
            markdown={getMarkdown(page, 'ckovseawg137b0b70g5am3s07', true)}
            fragments={getMarkdown(page, 'ckovseawg137b0b70g5am3s07')}
            isAbout
            isShowing
            isPatologica
            marginLeftContainerMobile="3%"
            containerColorEmDesk="#231f20"
            isAddressAndTimes
            isIncreasingContainerWidth
            containerWidth="49%"
          />
        </Part>
        <Part
          gridColumn={'2 / -2'}
          largeTitle
          notPadding
          notLeft
          shortPadding
          borderBottom
        >
          <PromptServiceContent // Equipe Medica
            markdown={getMarkdown(page, 'ckovsinjc140u0b672a142sk1', true)}
            fragments={getMarkdown(page, 'ckovsinjc140u0b672a142sk1')}
            padding
            notTitleBold
            facilitiesForYou
            isBottom
            prontoAtendimento
            isFacilities
            isPatologica
            breakLineBr
          />
          <PromptServiceContent // Equipe Medica - Dropdown
            markdown={getMarkdown(page, 'ckpe6zwu86r710c25v56z6i68', true)}
            fragments={getMarkdown(page, 'ckpe6zwu86r710c25v56z6i68')}
            padding
            notTitleBold
            facilitiesForYou
            isDropdownDesktop
            isPatologica
            containerPaddingDesk="3.125rem 0 4.375rem"
            contentFontSizeMob="0.875rem"
            breakLineBr
            noPaddingMobile
          />
        </Part>

        <Part gridColumn={'2 / -2'} largeTitle notLeft borderBottom>
          <PromptServiceContent // Eletrocardiograma
            markdown={getMarkdown(page, 'ckpe730q06pk80b74yo9zr5dz', true)}
            fragments={getMarkdown(page, 'ckpe730q06pk80b74yo9zr5dz')}
            // fragments={getMarkdown(page, 'ckovyau4w0jyb0b75brtug8y7')}
            // padding
            notTitleBold
            facilitiesForYou
            isBottom
            prontoAtendimento
            isPatologica
            isFacilities
          />
        </Part>
        <Part gridColumn={'2 / -2'} largeTitle notLeft borderBottom>
          <PromptServiceContent // Teste Ergométrico
            markdown={getMarkdown(page, 'ckpe74evk6qhp0b745yqgv9h4', true)}
            fragments={getMarkdown(page, 'ckpe74evk6qhp0b745yqgv9h4')}
            notTitleBold
            facilitiesForYou
            isBottom
            prontoAtendimento
            isFacilities
            isPatologica
            containerPaddingDesk="3.125rem 0 4.375rem"
            containerPaddingLap="1.875rem 0 0 0"
            marginContentLap="0"
            contentFontSizeMob="0.875rem"
          />
        </Part>
        <Part gridColumn={'2 / -2'} largeTitle notLeft borderBottom>
          <PromptServiceContent // Estudo Eletrofisiológico Invasivo Diagnóstico e Terapêutico
            markdown={getMarkdown(page, 'ckpe76rr46tvc0b761r0fkusr', true)}
            fragments={getMarkdown(page, 'ckpe76rr46tvc0b761r0fkusr')}
            padding
            notTitleBold
            facilitiesForYou
            isBottom
            prontoAtendimento
            isFacilities
            isPatologica
            containerPaddingDesk="3.125rem 0 4.375rem"
            containerPaddingLap="1.875rem 0 0 0"
            contentFontSizeMob="0.875rem"
          />
        </Part>
        <Part gridColumn={'2 / -2'} largeTitle notLeft borderBottom>
          <PromptServiceContent // Mapa
            markdown={getMarkdown(page, 'ckpe77vvk6rvr0b74uecsf6z7', true)}
            fragments={getMarkdown(page, 'ckpe77vvk6rvr0b74uecsf6z7')}
            padding
            notTitleBold
            facilitiesForYou
            isBottom
            prontoAtendimento
            isFacilities
            isPatologica
            containerPaddingDesk="3.125rem 0 4.375rem"
            containerPaddingLap="1.875rem 0 0 0"
            contentFontSizeMob="0.875rem"
          />
        </Part>
        <Part gridColumn={'2 / -2'} largeTitle notLeft borderBottom>
          <PromptServiceContent // Holter
            markdown={getMarkdown(page, 'ckpe79jag6t1s0c255l0qubm4', true)}
            fragments={getMarkdown(page, 'ckpe79jag6t1s0c255l0qubm4')}
            padding
            notTitleBold
            facilitiesForYou
            isBottom
            prontoAtendimento
            isFacilities
            isPatologica
            containerPaddingDesk="3.125rem 0 4.375rem"
            containerPaddingLap="1.875rem 0 0 0"
            contentFontSizeMob="0.875rem"
          />
        </Part>
        <Part gridColumn={'2 / -2'} largeTitle notLeft borderBottom>
          <PromptServiceContent // Nucleo de Cardio-Oncologia
            markdown={getMarkdown(page, 'ckpe7bpzs6vhq0c25xxwtiocf', true)}
            fragments={getMarkdown(page, 'ckpe7bpzs6vhq0c25xxwtiocf')}
            padding
            notTitleBold
            facilitiesForYou
            isBottom
            prontoAtendimento
            isFacilities
            isPatologica
            containerPaddingDesk="3.125rem 0 4.375rem"
            containerPaddingLap="1.875rem 0 0 0"
            contentFontSizeMob="0.875rem"
          />
        </Part>
        <Part gridColumn={'2 / -2'} largeTitle notLeft borderBottom>
          <PromptServiceContent // Cirurgia minimamnete invasiva para doença valvar
            markdown={getMarkdown(page, 'ckpe7dbv46uva0c255v6ozq4g', true)}
            fragments={getMarkdown(page, 'ckpe7dbv46uva0c255v6ozq4g')}
            padding
            notTitleBold
            facilitiesForYou
            isBottom
            prontoAtendimento
            isFacilities
            isPatologica
            containerPaddingDesk="3.125rem 0 4.375rem"
            containerPaddingLap="1.875rem 0 0 0"
            contentFontSizeMob="0.875rem"
          />
        </Part>
        <Part gridColumn={'2 / -2'} largeTitle notLeft borderBottom>
          <PromptServiceContent // Cirurgia de revascularização do miocárdio
            markdown={getMarkdown(page, 'ckpe7ep8w6xbb0b76u7eft24y', true)}
            fragments={getMarkdown(page, 'ckpe7ep8w6xbb0b76u7eft24y')}
            padding
            notTitleBold
            facilitiesForYou
            isBottom
            prontoAtendimento
            isFacilities
            isPatologica
            containerPaddingDesk="3.125rem 0 4.375rem"
            containerPaddingLap="1.875rem 0 0 0"
            contentFontSizeMob="0.875rem"
          />
        </Part>
        <Part gridColumn={'2 / -2'} largeTitle notLeft borderBottom>
          <PromptServiceContent // Treinamento para doença cardiaca
            markdown={getMarkdown(page, 'ckpe7ggio6xyv0b761zgamf48', true)}
            fragments={getMarkdown(page, 'ckpe7ggio6xyv0b761zgamf48')}
            padding
            notTitleBold
            facilitiesForYou
            isBottom
            prontoAtendimento
            isFacilities
            isPatologica
            containerPaddingDesk="3.125rem 0 4.375rem"
            containerPaddingLap="1.875rem 0 0 0"
            contentFontSizeMob="0.875rem"
          />
        </Part>
        <Part gridColumn={'2 / -2'} largeTitle notLeft borderBottom>
          <PromptServiceContent // Tratamento das doenças da aorta
            markdown={getMarkdown(page, 'ckpe7iga06wqd0b74emsgzrap', true)}
            fragments={getMarkdown(page, 'ckpe7iga06wqd0b74emsgzrap')}
            padding
            notTitleBold
            facilitiesForYou
            isBottom
            prontoAtendimento
            isFacilities
            isPatologica
            containerPaddingDesk="3.125rem 0 4.375rem"
            containerPaddingLap="1.875rem 0 0 0"
            contentFontSizeMob="0.875rem"
          />
        </Part>
        <Part gridColumn={'2 / -2'} largeTitle notLeft borderBottom>
          <PromptServiceContent // Insuficiencia cardiaca avançada
            markdown={getMarkdown(page, 'ckpe7kx0g6z5l0c2577tzn2qv', true)}
            fragments={getMarkdown(page, 'ckpe7kx0g6z5l0c2577tzn2qv')}
            padding
            notTitleBold
            facilitiesForYou
            isBottom
            prontoAtendimento
            isFacilities
            isPatologica
            containerPaddingDesk="3.125rem 0 4.375rem"
            containerPaddingLap="1.875rem 0 0 0"
            contentFontSizeMob="0.875rem"
          />
        </Part>
        <Part gridColumn={'2 / -2'} largeTitle notLeft borderBottom>
          <PromptServiceContent // Cateterismo cardiaco / coronariografia
            markdown={getMarkdown(page, 'ckpe7n1eg6ywg0b74evyzjuju', true)}
            fragments={getMarkdown(page, 'ckpe7n1eg6ywg0b74evyzjuju')}
            padding
            notTitleBold
            facilitiesForYou
            isBottom
            prontoAtendimento
            isFacilities
            isPatologica
            containerPaddingDesk="3.125rem 0 4.375rem"
            containerPaddingLap="1.875rem 0 0 0"
            contentFontSizeMob="0.875rem"
          />
        </Part>
        <Part gridColumn={'2 / -2'} largeTitle notLeft borderBottom>
          <PromptServiceContent // Centro de doenças cardiacas valvares
            markdown={getMarkdown(page, 'ckpe7rxbs72gh0b76ogd8xrdf', true)}
            fragments={getMarkdown(page, 'ckpe7rxbs72gh0b76ogd8xrdf')}
            padding
            notTitleBold
            facilitiesForYou
            isBottom
            prontoAtendimento
            isFacilities
            isPatologica
            containerPaddingDesk="3.125rem 0 4.375rem"
            containerPaddingLap="1.875rem 0 0 0"
            contentFontSizeMob="0.875rem"
          />
        </Part>
        <Part gridColumn={'2 / -2'} largeTitle notLeft borderBottom>
          <PromptServiceContent // Avaliação eletronica de pacientes portadores de marcapasso
            markdown={getMarkdown(page, 'ckpe7uu9k74ln0c256z05p4t2', true)}
            fragments={getMarkdown(page, 'ckpe7uu9k74ln0c256z05p4t2')}
            padding
            notTitleBold
            facilitiesForYou
            isBottom
            prontoAtendimento
            isFacilities
            isPatologica
            containerPaddingDesk="3.125rem 0 4.375rem"
            containerPaddingLap="1.875rem 0 0 0"
            contentFontSizeMob="0.875rem"
          />
        </Part>
        <Part gridColumn={'2 / -2'} largeTitle notLeft borderBottom>
          <PromptServiceContent // Ecocardiograma trantoracico
            markdown={getMarkdown(page, 'ckpe7xe34749j0b76646847k6', true)}
            fragments={getMarkdown(page, 'ckpe7xe34749j0b76646847k6')}
            padding
            notTitleBold
            facilitiesForYou
            isBottom
            prontoAtendimento
            isFacilities
            isPatologica
            containerPaddingDesk="3.125rem 0 4.375rem"
            containerPaddingLap="1.875rem 0 0 0"
            contentFontSizeMob="0.875rem"
          />
        </Part>
        <Part gridColumn={'2 / -2'} largeTitle notLeft borderBottom>
          <PromptServiceContent // Ecocardiograma transesofagico
            markdown={getMarkdown(page, 'ckpe7yuk074ht0b76r54q80ho', true)}
            fragments={getMarkdown(page, 'ckpe7yuk074ht0b76r54q80ho')}
            padding
            notTitleBold
            facilitiesForYou
            isBottom
            prontoAtendimento
            isFacilities
            isPatologica
            containerPaddingDesk="3.125rem 0 4.375rem"
            containerPaddingLap="1.875rem 0 0 0"
            contentFontSizeMob="0.875rem"
          />
        </Part>
        <Part gridColumn={'2 / -2'} largeTitle notLeft>
          <PromptServiceContent // Ecocardiograma de estresse farmacologico ou de exercicio
            markdown={getMarkdown(page, 'ckpe803b475so0c25qjqxlkj0', true)}
            fragments={getMarkdown(page, 'ckpe803b475so0c25qjqxlkj0')}
            padding
            notTitleBold
            facilitiesForYou
            isBottom
            prontoAtendimento
            isFacilities
            isPatologica
            containerPaddingDesk="3.125rem 0 4.375rem"
            containerPaddingLap="1.875rem 0 0 0"
            contentFontSizeMob="0.875rem"
          />
        </Part>
        <Part
          gridColumn={'1 / -1'}
          isNotBorder
          isMarginBottomCustomizedMob
          isMarginFullWidth
        >
          {components.length >= 1 && Components(doctorAndSpecialtyContent)}
        </Part>
        {page?.[0]?.qa && (
          <Part
            gridColumn={'2 / -2'}
            paddingBottomQa
            isMarginBottomCustomizedMob
          >
            <Qa
              title={'Perguntas Frequentes'}
              data={page?.[0]?.qa}
              showFaqLink="/faq/coronavirus/"
            />
          </Part>
        )}
        <Part
          gridColumn={'1 / -1'}
          isLastSection
          isNotBorder
          isMarginFullWidth
          isMarginTopCustomizedMob
        >
          {components.length >= 1 && Components(highlightContent)}
        </Part>
        {page?.[0]?.posts?.length > 0 && (
          <Part
            gridColumn={'2 / -2'}
            isNotBorder
            padding
            borderTop
            isMarginTopCustomizedMob
          >
            <ListPosts
              title="Blog do Sírio-Libanês"
              postsToShow={3}
              posts={page?.[0]?.posts}
              headerLink={`/${page[0]?.customURLBlog ?? 'blog/'}`}
            />
          </Part>
        )}
      </GridContainer>
    </>
  );
};

const Bucomaxilofacial = ({ page, pageContext }) => {
  const [isDesktop, setDesktop] = useState(true);

  const updateMedia = () => {
    if (typeof window !== 'undefined') {
      setDesktop(window.innerWidth > 1024);
    }
  };

  useEffect(() => {
    if (typeof window !== 'undefined') {
      setDesktop(window.innerWidth > 1024);
    }
  }, []);

  useEffect(() => {
    if (typeof window !== 'undefined') {
      window.addEventListener('resize', updateMedia);
      return () => window.removeEventListener('resize', updateMedia);
    }
  });

  return (
    <Layout>
      <SEO dataSeo={page} />
      {RenderPage(page, isDesktop, pageContext)}
    </Layout>
  );
};

export default props => {
  return (
    <StaticQuery
      query={graphql`
        query {
          gcms {
            site(where: { id: "ckgi8dg9k08b10a74cf20cxgk" }) {
              pages(where: { id: "ckpe83y7475a70b74vxgnaegc" }) {
                featuredMarketing(locales: [en, es, pt_BR]) {
                  id
                  type
                  breadcrumb
                  backgroundColor {
                    hex
                  }
                  images {
                    handle
                    width
                    height
                    url
                  }
                  activeHighlight
                  highlight
                  title
                  fragments {
                    id
                    name
                    type
                    markdown
                  }
                }
                components(locales: [pt_BR, en]) {
                  __typename
                  ... on GraphCMS_DoctorAndSpecialtyContent {
                    id
                    name
                    type
                    title
                    subtitle
                    estado
                    images {
                      handle
                      width
                      height
                      url
                    }
                    specialties {
                      id
                      name
                      slug
                      customUrl
                      unity
                    }
                    fragments {
                      id
                      name
                      type
                      markdown
                    }
                  }
                  ... on GraphCMS_HighlightContent {
                    id
                    name
                    type
                    backgroundColorInitial {
                      hex
                    }
                    backgroundColorFinal {
                      hex
                    }
                    title
                    customTitle
                    description
                    images {
                      handle
                      width
                      height
                      url
                    }
                    fragments {
                      id
                      name
                      type
                      markdown
                    }
                  }
                }
                fragments {
                  id
                  name
                  localizations {
                    singletexts
                    markdown
                  }
                }
                qa(locales: pt_BR) {
                  titulo
                  pergunta
                  autor
                  doctors(first: 1000, locales: [pt_BR, en]) {
                    id
                    id_api
                    active
                    assignment
                    name
                  }
                }
                seo {
                  metaTitle
                  metaDescription
                  noIndex
                  localizations {
                    metaTitle
                    metaDescription
                    noIndex
                  }
                }
                customURLBlog
                posts {
                  id
                  slugPost
                  title
                  topic
                  author
                  doctors(first: 1000, locales: [pt_BR, en]) {
                    id
                    id_api
                    active
                    assignment
                    name
                  }
                  assetpicker {
                    handle
                    height
                    width
                  }
                  categories {
                    tag
                  }
                }
              }
            }
          }
        }
      `}
      render={response => {
        return <Bucomaxilofacial page={response.gcms.site.pages} {...props} />;
      }}
    />
  );
};
